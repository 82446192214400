import React from 'react';
const blocks = {
    Spinner: () =>{
        return (
            <div className="container">
                 <span className="spinner"></span>
            </div>
        )
    }
} 


class CustomBlock extends React.Component {
    render (){
         return blocks[this.props.component]()
    }
}
export default CustomBlock;