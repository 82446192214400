import React from "react";
import Uploader from "./components/Uploader";
import {Input} from "antd";
const fieldOpts = [
    {
        name:'storeTitle',
        label:'Store Name',
        type:'text',
        required:true,
        placeholder:'Enter your store name',
        recordType:'store',
        stepIcon:'trademark',
        content:<Input type="text" required={true} placeholder="Enter your store name" name="storeTitle"/>

    },
    {
        name:'storeIcon',
        label:'Icon',
        type:'file',
        fileType:'image/png',
        required:true,
        placeholder:'Upload a Store Icon',
        recordType:'store',
        stepIcon:'file-image',
        content:<Uploader name="file" fileType="image/png" title="Icon" required={true}/>
    },
   
    {
        name:'storeUrl',
        label:'Website',
        type:'url',
        required:true,
        recordType:'store',
        stepIcon:'home',
        content:<Input type="url" required={true} placeholder="Enter your website" name="storeUrl"/>
    },
    {
        name:'title',
        label:'Title',
        type:'text',
        required:true,
        placeholder:'Enter a Title',
        recordType:'catalog'
    },
    {
        name:'cover',
        label:'Cover Image',
        type:'file',
        fileType:'image/jpeg',
        required:true,
        placeholder:'Upload a Cover Image',
        recordType:'catalog'
    },
    {
        name:'pdf',
        label:'Full Catalog PDF',
        type:'file',
        fileType:'application/pdf',
        required:true,
        placeholder:'Upload full catalog PDF',
        recordType:'catalog'
    },
    {
        name:'store',
        type:'select',
        label:'store',
       // options:[{title:"CTP",value:'124'},{title:"PL",value:'125'}],
        required:true,
        recordType:'catalog'
    },
    {
        name:'published',
        type:'checkbox',
        label:'Published',
        options:[{title:'Published',value:true},{title:'Unpublished',value:false}],
        required:false,
        recordType:'catalog'
    },
    {
        name:'title',
        type:'text',
        label:'Title',
        required:false,
        recordType:'page'
    },
    {
        name:'tags',
        type:'textarea',
        label:'Tags',
        required:false,
        recordType:'page'
    },
    {
        name:'description',
        type:'textarea',
        label:'Description',
        required:false,
        recordType:'page'
    },
    {
        name:'image',
        type:'file',
        label:'Image',
        fileType:'image/jpeg',
        required:true,
        recordType:'page'
    },
    {
        name:'pdf',
        type:'file',
        label:'PDF',
        fileType:'application/pdf',
        required:'true',
        recordType:'page'
    },
  
    {
        name:'text',
        type:'textarea',
        label:'PDF Text',
        required:false,
        recordType:'page'
    },
    {
        name:'published',
        type:'checkbox',
        label:'Published',
        required:false,
        recordType:'page'
    },
    {
        name:"name",
        type:"text",
        label:'Name',
        required:true,
        recordType:'user'
    },
    {
        name:'username',
        type:'text',
        label:'Username',
        required:true,
        recordType:'user'
    },
    {
        name:'role',
        type:'select',
        multiple:true,
        label:'Roles',
        options:[{title:'Admin',value:"admin"},{title:'Marketing',value:"marketing"},{title:'User',value:'user'},{title:'Customer Service',value:'customerservice'}],
        required:false,
        recordType:'user'
    },
    {
        name:'store',
        type:'checkbox',
        label:'PL',
        required:false,
        recordType:'user'
    },
    {
        name:'password',
        type:'password',
        label:'Password',
        required:true,
        recordType:'user'
    }
]

export function lookupField(input){
   const options =  fieldOpts.filter((t)=>{
        if(input === t.recordType){
            return t;
        }
    });
    return options;
}
export function returnFields(type){
    return fieldOpts.filter((f)=>{
        if (f.recordType === type){
            return f;
        }
    })
}