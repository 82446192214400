import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from './AsyncComponent';
import Template from './Template';
import {API}  from './ApiService';
const AsyncLogin  = asyncComponent(() => import('./Login'));
const AsyncHome = asyncComponent(() => import('./Home'));
const AsyncMain = asyncComponent(() => import('./Main'));
const AsyncUsers = asyncComponent(() => import('./Users'));
const AsyncUser = asyncComponent(()=>import('./User'));
const AsyncCatalogs = asyncComponent(()=> import('./Catalogs'));
const AsyncCatalog = asyncComponent(()=> import('./Catalog'));
const AsyncProducts = asyncComponent(() => import('./Products'));
const AsyncNotFound = asyncComponent(() => import('./NotFound'));
const AsyncMyAccount = asyncComponent(()=> import('./MyAccount'));
const AsyncDiscounts = asyncComponent(()=>import('./Discounts'));
const AsyncRoles = asyncComponent(()=> import('./Roles'));
const AsyncDownloads = asyncComponent(()=> import('./Downloads'));
const AsyncForbidden = asyncComponent(()=>import('./Forbidden'));


let decoded = {};
const auth = () => {
     decoded = API.auth();
    return decoded;
}

const  PrivateRoute = ({ component: Component, ...rest})  => {
    return   <Route {...rest} render={(props) => (
        auth() ? <Component {...props} signOut={API.signOut} decoded={decoded}/> : <Redirect to='/login'/> 
    )
    } />
}

const Router = () => {  
  return (
    
          <BrowserRouter>
            <Template>
                <Switch>
                    <PrivateRoute exact path="/" component={AsyncHome}/>
                    <PrivateRoute exact path="/analytics" component={AsyncHome}/>
                    <PrivateRoute exact path="/users" component={AsyncUsers}/>
                    <PrivateRoute exact path="/users/roles" component={AsyncRoles}/>
                    <PrivateRoute exact path="/users/:user" component={AsyncUser}/>
                    <PrivateRoute exact path="/catalogs" component={AsyncCatalogs}/>
                    <PrivateRoute path="/catalog/:catalog_id" component={AsyncCatalog}/>
                    <PrivateRoute exact path="/my-account" component={AsyncMyAccount}/>
                    <PrivateRoute exact path="/discounts" component={AsyncDiscounts}/>
                    <PrivateRoute exact path="/downloads" component={AsyncDownloads}/>
                    <Route exact path="/main" component={AsyncMain}/>
                    <Route path="/login" component={AsyncLogin} />
                    <PrivateRoute exact path="/products" component={AsyncProducts}/>
                    <PrivateRoute exact path="/releases" component={AsyncProducts}/>
                    <Route path="/403" component={AsyncForbidden}/>
                    <Route component={AsyncNotFound}/>
                </Switch>
            </Template>
        </BrowserRouter>
     
    
  )  
}
export default Router;