import { checkCookie } from '../Cookie';
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL:'https://dl.inspiredinstyle.com/api',
    timeout:60 * 1000,
    headers:{
        'X-Access-Token':checkCookie('token')
    }
})
axiosInstance.interceptors.response.use(
    response=>response,
    error=>{
        console.log(error.message)
        if(error.response){
            switch(error.response.status){
                case 403:
                    window.location = '/403'
                    break;
                case 404:
                    throw 'API Error: Not Found!'
            }
        } else {
           return false
        }
       
    }
)
export default axiosInstance;