import React from 'react';
import {withRouter} from 'react-router-dom';
import {Drawer, Form, Button, Col, Row,Input, Select,DatePicker,Icon,Checkbox,Affix,Steps,Alert} from 'antd';
import {lookupField} from '../fieldType';
import Uploader from './Uploader';
import { API } from './ApiService';
const { Option } = Select;
const {TextArea} = Input;

class AddRecord extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            stores:[],
            visible:false,
            fields:[],
            loadingField:null,
            loading:false,
            error:null
        }
        this.onClose = this.onClose.bind(this);
        this.showDrawer = this.showDrawer.bind(this);
        this.buildForm = this.buildForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getOptions = this.getOptions.bind(this);
    }
    buildForm(type){
        const fields = lookupField(type).map((f)=>{
            return f
        })
        this.setState({
            visible:true,
            fields
        })
        
    }
    getOptions(field){
        const options = this.state.fields.filter((f)=>{
            if(f.name === field){
                return f.options
            }
        })[0];
        switch(this.props.recordType){
            case 'catalog':
                   
                    if(!options){
                       this.setState({
                           loadingField:field
                       })
                 
                        API.getStores()
                        .then((response)=>{
                            if(response.success){
                                const fields = this.state.fields.map((f)=>{
                                    if(f.name === field){
                                        f.options = response.stores
                                    }
                                    return f
                                })
                                this.setState({
                                    fields,
                                    loadingField:null
                                })
                            } else {
                                console.log(response.message)
                            }
                           
                        },(err)=>{
                            console.log(err);
                        })
                       
                      
                    }
                    break;
            case 'page':
                    console.log('page');
                    break;
            
            default:
                break;
        }
    }
    showDrawer(){
       this.buildForm(this.props.recordType)
    }
    handleSubmit(event){
        event.preventDefault();
        try {
            let formData = new FormData();
            const fields = this.state.fields;
            fields.forEach((f)=>{
                if(f.required && !f.value){
                    throw(`Missing Information: ${f.name}`)
                } else {
                    if(f.type==='file'){
                        console.log(f);
                        formData.append('file',f.value.originFileObj)
                } else {
                    if(f.value){
                        formData.append(f.name,f.value)
                    }
                    
                }
                }
            });
            this.setState({
                loading:true
            })
            switch(this.props.recordType){
                case 'store':  
                    API.addStore(formData)
                        .then((response)=>{
                            if (response.success){
                                this.setState({
                                    visible:false,
                                    loading:false
                                });
                                setTimeout(()=>{
                                    this.props.getStores();
                                },200)
                            } else {
                                this.setState({
                                    error:response.message,
                                    loading:false
                                })
                            }
    
                        }).catch((error)=>{
                            this.setState({
                                error:error.message,
                                loading:false
                            })
                        })
                    break;
                case 'catalog':
                    API.addCatalog(formData)
                        .then((response)=>{
                            if(response.success){
                                this.setState({
                                    visible:false,
                                    loading:false
                                })
                                setTimeout(()=>{
                                    window.location = '/catalogs';
                                },200);
                            } else {
                                this.setState({
                                    error:response.message,
                                    loading:false
                                })
                            }
                        }).catch((error)=>{
                            console.log(error)
                            this.setState({
                                error:error.message,
                                loading:false
                            })
                        })
                    break;
                case 'page':
                    formData.append('catalog_id', this.props.catalog_id)
                    API.addPage(formData)
                        .then((response)=>{
                            if(response.success){
                                this.setState({
                                    visible:false,
                                    loading:false,
                                    fields:[]
                                })
                                setTimeout(()=>{
                                    this.props.closeAdd(true);
                                },200)
                            } else {
                                this.setState({
                                    error:response,
                                    loading:false
                                })
                            }
                        }).catch((error)=>{
                            console.log(error)
                            this.setState({
                                error:error,
                                loading:false
                            })
                        })
                        break;
                    case 'user':
                        console.log(formData);
                    API.createUser(formData)
                            .then((response)=>{
                                if(response.success){
                                    this.setState({
                                        visible:false,
                                        loading:false
                                    })
                                    setTimeout(()=>{
                                        this.props.closeAdd(true);
                                    },200)
                                } else {
                                    this.setState({
                                        error:response.message,
                                        loading:false
                                    })
                                }
                            }).catch((error)=>{
                                console.log(error);
                                this.setState({
                                    error:error.message || error,
                                    loading:false
                                })
                            })
                            
                        break;
                default:
                    this.setState({
                        error:'Action not found!',
                        loading:false
                    })
                    break;
            }
        } catch(error){
            this.setState({
                error,
                loading:false
            })
        }
        
       
        
    }
    handleChange(event){
        
        const updatedValues = this.state.fields.map((f)=>{
            if(typeof(event)==='string'){
                event = JSON.parse(event)
            }
            if (event.target){
                if(f.name ===  event.target.name){
                    if(f.type==='checkbox'){
                        f.checked = event.target.checked
                        f.value = event.target.checked
                        console.log(event.target.checked)
                    } else {
                            f.value = event.target.value
                    }
                   
                }
                return f;
            } else if(f.type === 'file' && f.name === event.file.fieldName){
                    console.log(f);
                    f.value = event.file
            } 
                return f;
            
            
        })
        this.setState({
            fields:updatedValues,
            error:null
        })
    }
  
   
    onClose(){
        this.setState({
            visible:false,
            fields:[],
            error:null
        })
        this.props.closeAdd();
    }
    componentDidMount(){
        if(!this.props.default){
            this.showDrawer();
        }
       /* API.getStores()
            .then((response)=>{
                if(response.success){
                    this.setState({
                        stores:response.stores
                    })
                }
            })
            */
    }
    render(){
        
        return (
            <span>
              
                <Drawer
                    title={`Add new ${this.props.recordType}`}
                    width={360}
                    placement='left'
                    onClose={this.onClose}
                    visible={this.state.visible}
                    >
                <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col span={24}>
                        {
                            this.state.fields.map((f,i)=>{
                                return (
                                    <Form.Item key={i} className="animated flipInX faster" label={f.label}>
                                        {
                                            f.type === "file" ?
                                            <Uploader value={f.value} title={f.name} name="file" fileType={f.fileType} onChange={this.handleChange} accept={f.fileType} required={f.required}/>
                                            : f.type === "select" ?
                                            <Select mode="multiple"  name={f.name} onChange={(e)=>this.handleChange(`{"target":{"value":"${e}","name":"${f.name}"}}`)} onDropdownVisibleChange={()=>{this.getOptions(f.name)}} required={f.required} loading={this.state.loadingField===f.name?true:false}>
                                                {
                                                    f.options ? 
                                                    f.options.map((o,i)=>{
                                                        return (
                                                            <Option key={i} value={o.storeTitle ? o._id : o.value}>{o.storeTitle || o.title}</Option>
                                                        )
                                                    })
                                                    :null
                                                }
                                            </Select>
                                            :f.type === "checkbox" ?
                                            <Checkbox value={f.checked} onChange={this.handleChange} name={f.name}>{f.name}</Checkbox>
                                            : f.type==="textarea" ?
                                                <TextArea rows={4} placeholder={f.placeholder} value={f.value} required={f.required} onChange={this.handleChange} name={f.name}/>
                                            : <Input type={f.type} placeholder={f.placeholder} value={f.value} required={f.required} onChange={this.handleChange} name={f.name}/>
                                        }
                                    </Form.Item>
                                )
                            })
                        }

                        </Col>
                    </Row>
                    <Affix offsetBottom={10} style={{textAlign:'center'}}>
                        <Button htmlType="submit" type="primary" loading={this.state.loading}>Submit</Button>
                    </Affix>
                    {
                        this.state.error ? 
                        <Alert type="error" message={this.state.error}/>
                        :null
                    }
                </Form>
                </Drawer>
            </span>
           
        )
    }
}
export default withRouter(AddRecord);