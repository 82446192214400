import React from 'react';
import '../css/style.css';
import '../css/animate.css';
import { Affix, Layout } from 'antd';
import Navigation from './Navigation';
import CustomBlock from './CustomBlocks'
import {checkCookie,setCookie,deleteCookie} from '../Cookie';
const { Header, Content, Footer } = Layout;
class Template extends React.Component {
    constructor(){
        super();
        this.state = {
            collapsed: checkCookie('sbC') === 'expand' ? true : false,
        }
        this.onCollapsed = this.onCollapsed.bind(this);
        this.signOut = this.signOut.bind(this);
    }
    signOut(){
        if(checkCookie('token')){
            deleteCookie('token');
            window.location = "/login";
        };
    }
    onCollapsed = (collapsed) => {
        this.setState({collapsed})
        setCookie('sbC',(collapsed ? 'expand' : 'collapse'),true);
    }
    render() {
        
        return (
            <Layout className="wrap">
                <Layout>
                        <Affix>

                          <Navigation  signOut={this.signOut}  navType={checkCookie('token') ? "defaultNav" : "publicNav"}/>
                        </Affix>
                    </Layout>
                    <Layout style={{minHeight: '100vh'}}>
                        {
                            checkCookie('token') ?
                            <Navigation navType="sideNav" collapsed={this.state.collapsed} onCollapsed={this.onCollapsed}/>
                            :null
                        }
                        <Layout>
                         
                            <Content style={{margin:'0 16px'}}>
                               
                                <div style={{padding:24, background:'#fff',minHeight:360}}>
                                    {   
                                        this.props.loading ? <CustomBlock component='Spinner'/>
                                        : this.props.children
                                    }
                                </div>
                            </Content>
                            <Footer style={{ textAlign:'center'}}>
                                JHC &copy; 2019
                            </Footer>
                        </Layout>
                    </Layout>
            </Layout>
         
        )
    }
}

export default Template;