import React from 'react';
import {Upload,Icon,Message, message,Progress} from 'antd';
const Dragger = Upload.Dragger;
class Uploader extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uploading:false,
            value:null
        }
        this.onChange = this.onChange.bind(this);
    }
    onChange(files){
        files.file.fieldName = this.props.title;
        this.setState({
            value:files.file.name
        })
        this.props.onChange(files);
    }
    render(){
        const properties = {
            name:'file',
            multiple:false,
            accept:this.props.accept,
            customRequest:()=>{},
            showUploadList:false,
            onChange(props){
                this.onChange(props);
                
            }
        }
        return(
            <span>
                {
                    this.state.uploading ? 
                    <span className="spinner"/>
                    :<Dragger {...properties} onChange={this.onChange}>
                    <h2>{this.props.title}
                        {this.props.fileType?
                            <span className="small"> ({this.props.fileType})</span>
                            :null}
                    </h2>
                    {
                        this.state.value ?
                        <p><strong>current: {this.state.value}</strong></p>
                        :null
                    }
                   
                     <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        
                    Note: Catalog files will only be uploaded, not processed.
                    </p>
                </Dragger>
                    
                }
            </span>
        )
    }

}
export default Uploader;