export function checkCookie(name,value){
    const regex = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$');
    return value ? (document.cookie.replace(regex,"$1") === value) : (document.cookie.replace(regex,"$1") || null);
  }

export function setCookie(name,value,expires) {
    const cookieDate = expires ? '0' : new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ).toUTCString();
    document.cookie = name + "=" + (value ? value : 1) + ";expires=" + cookieDate + ";path=/;";
  }

export function deleteCookie(name){
    document.cookie = name + "=;expires=Fri, 31 Dec 1999 23:59:59 GMT;path=/;"; 
}
export async function checkStorage(name,value){
  await null;
  return value ?  window.localStorage.getItem(name) === value : window.localStorage.getItem(name)
}
export async function setStorage(name,value){
  await null;
  return window.localStorage.setItem(name,JSON.stringify(value))
}
export async function deleteStorage(name){
  await null;
  return window.localStorage.removeItem(name)
}
export async function appendStorage(name,value){
  await null;
  return checkStorage(name)
    .then((existing)=>{
      const data = existing ? JSON.parse(existing) : [];
      data.unshift(value);
      if(data.length > 150) data.pop()
      setStorage(name,data)
    })
}