import React from 'react';
import { Menu, Icon, Layout, Affix,Button,Breadcrumb } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import AddRecord from './AddRecord';
const { Sider } = Layout;
const SubMenu = Menu.SubMenu;
const menuItems = {
    default:[
        {
            name:'Home',
            url:'/',
            icon:'home'
        },
        {
            name:'Analytics',
            url:'/analytics',
            icon:'area-chart'
        }
    ],
    users: [
        {
            name:'User Dashboard',
            url:'/users',
            icon:'team'
        },
        {
            name:'Roles',
            url:'/users/roles',
            icon:'safety-certificate'
        }
    ],
    /*
    catalogs:[
        {
            name:"Add Catalog",
            modal:()=><AddRecord recordType="catalog" icon="read"/>,
            icon:'plus-circle'
        }
    ],
    catalog:[
        {
            name:'Add Page',
            modal:()=><AddRecord recordType="page" icon="file-add"/>
        }
    ],
    
    'my-account':[
        {
            name:"Add Store",
            modal:()=><AddRecord recordType="store" icon="shop"/>
        }
    ]
    */
}




class Navigation extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            navType:'',
        }
    this.path = this.path.bind(this);
    }
   path(){
        const mainPath = this.props.location.pathname.split('/')[1]
        return mainPath === "" ? "default" : this.props.location.pathname.split('/')[1]
    }
    componentDidMount(){
            this.setState({
                navType:this.props.navType
            });
    }
    componentDidUpdate(prevProps){
        if(prevProps != this.props){
            this.setState({
                collapsed:this.props.collapsed,
                navType:this.props.navType
            })
        }
    }
   
    render() {
       return (
           this.state.navType ==="defaultNav"?
           <Menu 
           selectedKeys={[this.path()]}
           mode="horizontal"
           style={{display:'flex',justifyContent:'flex-end'}}
           >
               <Menu.Item key="/">
               <a href="/"> <Icon type="dashboard" />Dashboard</a>
               </Menu.Item>
               <SubMenu title={<span className="submenu-title-wrapper"><Icon type="shop"/>Products</span>}>
                    <Menu.Item key="/releases">
                        <a href="/releases"><Icon type="rocket"/>New Release</a>
                    </Menu.Item>
                    <Menu.Item key="/products">
                        <a href="/products"><Icon type="barcode"/>Current Products</a>
                    </Menu.Item>
                    
                </SubMenu>    
             <Menu.Item key="/downloads">
                 <a href="/downloads"><Icon type="download" />Downloads</a>
                 </Menu.Item>
              <Menu.Item key="/discounts">
                <a href="/discounts"><Icon type="percentage"/>Discounts</a>
              </Menu.Item>
               <Menu.Item key="/catalogs">
                   <a href="/catalogs"><Icon type="book" />Catalogs</a>
               </Menu.Item>
           
           <SubMenu title={<span className="submenu-title-wrapper"><Icon type="user"/>Accounts</span>}>
               <Menu.Item key="/users">
                   <a href="/users">Users</a>
               </Menu.Item>
               <Menu.Item key="/myAccount">
                   <a href="/my-account">My Account</a>
               </Menu.Item>
               <Menu.Item key="/logOut">
                   <Button onClick={this.props.signOut}>Log Out</Button>
               </Menu.Item>
           </SubMenu>
           
               <Menu.Item key="home">
               <a href="/"><Icon type="home" /> Go Home</a>
               </Menu.Item>
           </Menu>
           : this.state.navType==="sideNav" ?
           <Sider 
           collapsible
           collapsed={this.props.collapsed}
           onCollapse={this.props.onCollapsed}
           >  
               
                        <Affix offsetTop="48">
                        <Menu theme="dark" selectedKeys={[this.path()]} mode="inline">
                            {
                                menuItems[this.path()] ?
                                    menuItems[this.path()].map((item,i)=>{
                                        return (
                                            <Menu.Item key={item.name}>
                                                { item.url ?
                                                        <Link to={item.url}>
                                                        <Icon type={item.icon}/>
                                                        <span>{item.name}</span>
                                                        </Link>
                                                    : item.modal ? 
                                                    item.modal()
                                                    :null
                                                }
                                            </Menu.Item>
                                            )
                                    })
                                    : null
                            } 
                            </Menu>


                        </Affix>
                    
                
                </Sider>
            :  <Menu 
            selectedKeys={[this.path()]}
            mode="horizontal"
            style={{display:'flex',justifyContent:'flex-end'}}
            >
                    <Menu.Item key="/">
                    <a href="/"><Icon type="home" /> Go Home</a>
                    </Menu.Item>
            </Menu>
       )
    }
}

export  default withRouter(Navigation);