import axiosInstance from './axiosInstance';
import { checkCookie, setCookie, deleteCookie } from '../Cookie';

export class API {
    static auth() {
            if(checkCookie('token')) {
              return axiosInstance.get('/me')
                    .then(response =>{
                        if (response.status === 200) {
                            return response;
                        } else {
                            console.log(response)
                            console.log('bad response')
                            return false;
                        }
                    }, error => {
                        console.log(error);
                        return false;
                    })
            } else {
                console.log('no token')
                return false;
            }
           
    }
    static async login(username,password){
        try {
            const response = await axiosInstance.post('/authenticate', {
                username,
                password
            });
            
                if (response.data.success) {
                    setCookie("token",response.data.token);
                    return response.data
                } else{
                    throw 'Username or Password Incorrect';
                }
           
        } catch (err){
            return err;
        }
       

    }
    static getImage(url){
        const filename = url.split('https://ctp-imgs.s3-us-west-1.amazonaws.com/')[1]
        console.log(url,filename)
        try {
            return axiosInstance.post('/admin/get-image',{
                filename
            },{
                onDownloadProgress:(progress)=>{
                    const percent =  (100 * progress.loaded) / progress.total;
                    console.log(percent)
                    return percent;
                }
            }).then((response)=>{
                console.log(response)
                return response.target
            })
          
        } catch (err){
            throw new Error("err",err)
        }
    }
    static async getStores(){
        try {
            const response = await axiosInstance.get('/admin/get-stores');
            if(response.data.success){
                console.log(response.data)
                return response.data;
            } else {
                throw new Error(response.data.message);
            }
        } catch(error){
            return error;
        }
    }
    static addStore(formData){
        try {
            return axiosInstance.post('/admin/new-store',formData).then((response)=>{
                if(response.data.success){
                    return response.data;
                } else {
                    throw new Error('There was a problem adding the store. ')
                }
                
            })
        } catch (error){
            return error;
        }

    }
    static addPage(formData){
        try {
           
            return axiosInstance.post('/admin/catalogs/pages/add',formData)
                .then((response)=>{
                    if(response){
                        if(response.data.success){
                            return response.data
                        } else{
                            throw(response.data.message)
                        }
                    } 
                }).catch((err)=>{
                    throw(err)
                })
        } catch(err) {
            return err;
        }
    }
    static deletePage(ids){
        try{
            return axiosInstance.post('/admin/catalogs/pages/delete',ids)
                .then((response)=>{
                    if(response){
                        if(response.data.success){
                            return response.data
                        } else {
                            throw(response.data.message)
                        }
                    }
                }).catch((err)=>{
                    throw(err)
                })
        } catch(err) {
            return err;
        }
    }
    static updatePage(pageObj){
        try {
            return axiosInstance.post('/admin/catalogs/pages/update/',pageObj)
                .then((response)=>{
                    if(response){
                        if (response.data.success){
                            return response.data
                        } else  {
                            throw response.data.message
                        }
                    } 
                }).catch((err)=>{
                    throw err
                })

        } catch(err){
            return err.message || err
        }
    }
    static deleteBox(boxObj){
        try {
            return axiosInstance.post('/admin/catalogs/pages/deleteBox',boxObj)
                .then((response)=>{
                    if(response){
                        if(response.data.success){
                            return response.data
                        } else {
                            throw response.data.message
                        }
                    }
                }).catch((err)=>{
                    throw err
                })
        } catch(err){
            return err
        }
    }
    static addCatalog(catalog){
        try {
            return axiosInstance.post('/admin/catalogs/add',catalog)
            .then((response)=>{
                if(response.data.success){
                    return response.data
                } else {
                    throw response.data.message
                }
            })
        } catch(error){
            console.log(error);
            return error
        }
    }
    static getCatalog(id){
        try {
            return axiosInstance.get(`/admin/catalogs/get/${id}`)
                .then((response)=>{
                    if(response){
                        if(response.data.success){
                            return response.data
                        } else {
                            throw(response.message)
                        }
                    } else {
                        throw 'There was a problem with your request'
                    }
                }).catch((err)=>{
                    throw(err)
                })

        } catch(error){
            return error.message || error
        }
    }
    static deleteCatalog(catalog){
        try{
            return axiosInstance.post('/admin/catalogs/delete',catalog)
                .then((response)=>{
                    if(response){
                        if (response.data.success){
                            return response.data
                        } else {
                            throw(response.message)
                        }

                        
                    } else {
                        throw new Error('There was an problem with your request')
                    }
                }).catch((err)=>{
                    throw(err)
                })
        } catch(error){
            return error;
        }
    }
    static deleteStore(ids){
        try {
            return axiosInstance.post('/admin/delete-stores',{
                ids
            }).then((response)=>{
                console.log(response)
                if (response.data.success){
                    return response.data;
                } else {
                    throw new Error('Error deleting stores')
                }
            })
        } catch(err){
            return (err)
        }
    } 
    static async getCatalogs(){
        try {
            const response = await axiosInstance.get('/admin/catalogs');

            return response
        } catch(err){
            return err;   
        }
    }
    static getProducts(query){
        try {
            return axiosInstance.post('/products', query)
                .then((response)=>{
                    if(response.data.products){
                        return response.data;
                    } else {
                        throw 'Error getting products'
                    }
            })
        } catch(err){
            throw err;
        }
    }
    static getReleases(query){
        try {
            return axiosInstance.post('/release', query)
                .then((response)=>{
                    if(response.data.products){
                        return response.data;
                    } else {
                        throw 'Error getting releases'
                    }
                })
        } catch(err){
            throw err;
        }
    }
    static deleteRelease(){
        try {
            return axiosInstance.get('/release-delete')
                .then((response)=>{
                    if(response.data.success){
                        return response.data
                    } else {
                        throw 'Could not delete all release data!'
                    }
                })
        } catch(err){
            throw err;
        }
    }
    static refreshRelease(){
        try {
            return axiosInstance.get('/release-refresh')
                .then((response)=>{
                    if(response.data.success){
                        return response.data
                    } else {
                        throw `Could not refresh releases ${response.data.error ?  response.data.error : ''}`
                    }
                })
        } catch(err){
            throw err;
        }
    }
    static refreshProducts(query){
        try {
            return axiosInstance.get(`/${query.pl ? 'pl' : 'ctp'}-product-refresh`)
                .then((response)=>{
                    if(response.data.success){
                        return
                    } else {
                        throw `Could not refresh products`
                    }
                })
        } catch(err){
            throw err;
        }
    }
    static skuLookup(query){
        try {
            return axiosInstance.post('/products/sku',query)
                .then((response)=>{
                    console.log(response)
                    if(response.data.success){
                        return response.data
                    } else {
                        throw 'Could  not find product'
                    }
                })
        } catch(err){
            throw err.message || err;
        }
    }
    static createDiscount(data){
        try {
            return axiosInstance.post('/admin/discounts/create',data)
            .then((response)=>{
                if(response){
                    if(response.data.success){
                        return response.data
                    } else {
                        throw(response.data)
                    }
                } else {
                    throw('could not create discount!')
                }
            }).catch((err)=>{
                throw(err);
            })
        } catch(error){
            return error
        }
    }
    static getDiscounts(){
        try {
            return axiosInstance.get('/admin/discounts')
                .then((response)=>{
                    if(response){
                        if(response.data.success){
                            return response.data;
                        } else {
                            throw(response.data)
                        }
                    } else {
                        throw('could not load discounts')
                    }
                }).catch((err)=>{
                    throw(err);
                })
        } catch(error){
            return error;
        }
    }
    static refreshDiscounts(){
        try {
            return axiosInstance.get('/refresh-price-rules')
            .then((response)=>{
                if(response){
                    if(response.data.success){
                        return response.data
                    } else {
                        throw(response.data)
                    }

                } else {
                    throw('could not refresh discounts')
                }
            }).catch((error)=>{
                 throw(error)
            })
        } catch(error){
            return error;
        }
    }
    static getDownloads(query){
        try {
            return axiosInstance.post('/admin/get-downloads',query)
            .then((response)=>{
                if(response.data.success){
                    console.log(response.data)
                    return response.data
                } else {
                    throw(response.data.message || 'Could not get downloads')
                }
            })
        } catch (error){
            return error
        }
    }
    static updateDownload(data,formData=null,progressCallback){
        try {
            return axiosInstance.post('/admin/downloads/edit/'+ data._id,formData ? formData : data,{
                onUploadProgress:progress => progressCallback(progress)
            })
            .then((response)=>{
                if(response.data.success){
                    return response.data
                } else {
                    throw(response.data.message || 'Problem updating download')
                }
            }).catch((error)=>{
                throw(error)
            });
        } catch(error){
            return error;
        }
    }
    static newDownload(data,formData=null,progressCallback){
        try {
            return axiosInstance.post('/admin/downloads', formData ? formData : data ,{
                onUploadProgress:progress => progressCallback(progress)
            })
            .then((response)=>{
                if(response.data.success){
                    return response.data
                } else {
                    throw(response.data.message || 'Problem uploading file!')
                }
            }).catch(error=>{
                throw error;
            })
        } catch(error){
            return error;
        }
    }
    static videoDownload(data,formData=null,progressCallback){
        try {
            return axiosInstance.post('/admin/new-video-download',formData ? formData : data,{
                onUploadProgress:progress=>progressCallback(progress)
            })
            .then((response)=>{
                if(response.data.success){
                    return response.data
                } else {
                    throw(response.data.message || 'Problem saving download')
                }
            }).catch((error)=>{
                throw(error)
            })
        } catch (error){
            return error
        }
    }
    static deleteDownload(ids){
        try {
            return axiosInstance.post('/admin/remove-downloads',ids)
                .then((response)=>{
                    if(response.data.success){
                        return response.data
                    } else {
                        throw(response.data.message || 'Problem deleting download')
                    }
                }).catch(error=>{
                    throw error;
                })
        } catch(error){
            return error
        }
    }
    static signOut() {
        deleteCookie('token');
        window.location  = '/';
    }
    static getUsers() {
        try {
            const response =  axiosInstance.get('/users');
            if(response){
                return response
            } else {
                throw new Error(response.data.message)
            }
            } catch(err){
             return err;
            }
        
    }
    static getUser(id){
        try{
            return axiosInstance.get('/users/'+id)
                .then((response)=>{
                    if(response.data.success){
                        return response.data
                    } else {
                        throw response.data.message || 'Could not find user'
                    }
                }).catch((error)=>{
                    throw error
                })
        } catch(error){
            return error
        }
    }
    static createUser(formData){
        try {
            return axiosInstance.post('/users',formData)
                .then((response)=>{
                    if(response){
                        if(response.data.success){
                            return response.data
                        } else {
                            throw(response.data)
                        }
                    } else {
                        throw('could not create user')
                    }
                })
        } catch(err){
            return err;
        }
    }
    static deleteUser(_id){
            try {
                return axiosInstance.delete('/users/'+_id)
                    .then((response)=>{
                        if(response) {
                            if(response.data.success){
                                return response.data
                            } else {
                                throw(response.data)
                            }
                    } else {
                        throw('Could not delete user')
                    }
            })
        } catch(error){
            return error
        }
    }
    static updateUser(_id,data){
        try {
            return axiosInstance.put('/users/'+_id,data)
                .then((response)=>{
                    if(response){
                        if(response.data.success){
                            return response.data
                        } else {
                            throw(response.data)
                        }
                    } else {
                        throw('could not update user!')
                    }
                })
        } catch(error){
            return error
        }
    }
    static getTags(query){
        try {
            return axiosInstance.post('/tags',query)
                .then((response)=>{
                    if(response){
                        if(response.data.success){
                            return response.data
                        } else {
                            throw(response.data)
                        }
                    } else {
                        throw('could not get tags!')
                    }
                })
        } catch(error){ 
            return error
        }
    }
    static productUpdate(data){
        try {
            return axiosInstance.put('/products',data)
                .then((response)=>{
                    if(response){
                        if(response.data.success){
                            return response.data
                        } else {
                            throw response.data
                        }
                    } else {
                        throw('could not update product')
                    }
                })
        } catch(error) {
            return error
        }
    }
    static productTagUpdate(data){
        try {
            return axiosInstance.put('/products/tags',data)
                .then((response)=>{
                    if(response){
                        if(response.data.success){
                            return response.data
                        } else {
                            throw(response.data)
                        }
                    } else {
                        throw('could not update tags!')
                    }
                })
        } catch(error){
            return error
        }
    }
}